<template>
  <div class="p-6 space-y-6">
    <div class="flex items-center justify-center col-span-4 space-x-2">
      <button @click="previousMonth">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-secondary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <datepicker
        style="cursor: pointer !important; width: 9rem"
        class="
          border-none
          text-2xl text-center
          date-input
          bg-secondary
          text-white
          rounded-md
          py-0
        "
        :placeholder="'Month'"
        :inputFormat="calFormat"
        :minimumView="minimumView"
        :maximumView="'year'"
        :initialView="'year'"
        :locale="lan"
        v-model="chartDate"
      ></datepicker>
      <button @click="nextMonth">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-secondary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
    <ul class="nav flex justify-center print-display">
      <li class="nav-item ml-1.5 mr-1.5" v-for="item in duration" :key="item">
        <button
          @click="changeCalFormat(item)"
          :class="{
            'bg-primary border-2 border-secondary': filter_type == item,
          }"
          class="
            mybtn
            border-primary border-2
            md:text-base
            hover:text-white hover:bg-primary hover:border-secondary
          "
        >
          <span class="capitalize">{{ $t(item) }}</span>
        </button>
      </li>
    </ul>
    <div class="grid grid-cols-4">
      <div class="col-span-3 sm:col-span-1">
        <label
          for="chart_type"
          class="block text-sm font-medium text-gray-700"
          >{{ $t("Type") }}</label
        >
        <select
          id="chart_type"
          name="chart_type"
          v-model="chart_type"
          class="
            mt-1
            block
            w-full
            pl-3
            pr-10
            py-2
            text-base
            border-gray-300
            focus:outline-none focus:ring-fuchsia-500 focus:border-fuchsia-500
            sm:text-sm
            rounded-md
          "
        >
          <option v-for="(item, i) in types" :key="i" :value="item">
            {{ $t(item) }}
          </option>
        </select>
      </div>
    </div>
    <!-- <div v-if="chart_type == 'all' && showCart">
      <canvas id="chart-all"></canvas>
    </div> -->
    <div v-if="chart_type == 'income' && showCart">
      <canvas id="chart-income"></canvas>
    </div>
    <div v-if="chart_type == 'expense' && showCart">
      <canvas id="chart-expense"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import Datepicker from "../../node_modules/vue3-datepicker";
import { de, it } from "date-fns/locale";

const monthList = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];
const COLORS = [
  "#4dc9f6",
  "#f67019",
  "#f53794",
  "#537bc4",
  "#acc236",
  "#166a8f",
  "#00a950",
  "#58595b",
  "#8549ba",
];
// const types = ["all", "income", "expense"];
const types = ["income", "expense"];
const duration = ["monthly", "yearly"];

export default {
  components: { Datepicker },
  data() {
    return {
      monthList,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      COLORS,
      types,
      duration,
      chart_type: "income",
      showCart: true,
      filter_type: "monthly",
      chartDate: new Date(),
      lan: this.$i18n.locale == "de" ? de : it,
      calFormat: "MM.yyyy",
      minimumView: "month",
    };
  },
  computed: {
    chartAll() {
      return this.$store.state.statistic.chartAll;
    },
    chartIncomes() {
      return this.$store.state.statistic.chartIncomes;
    },
    chartExpense() {
      return this.$store.state.statistic.chartExpense;
    },
  },
  watch: {
    chartDate: function () {
      this.getChartAll();
    },
    chart_type() {
      this.getChartAll();
    },
    filter_type() {
      this.getChartAll();
    },
  },
  methods: {
    getChartAll() {
      this.showCart = false;
      this.$store
        .dispatch("statistic/getChartAll", {
          from: `${this.chartDate.getFullYear()}-${
            this.filter_type == "monthly"
              ? (this.chartDate.getMonth() + 1).toString().padStart(2, "0")
              : "1"
          }-1`,
          to: `${this.chartDate.getFullYear()}-${
            this.filter_type == "monthly"
              ? (this.chartDate.getMonth() + 1).toString().padStart(2, "0")
              : "12"
          }-${this.endMonthDay()}`,
          type: this.chart_type,
        })
        .then(() => {
          this.showCart = true;
          setTimeout(() => {
            const ctx = document.getElementById(`chart-${this.chart_type}`);
            let categories = this.chartAll.map((item) => {
              return item.category;
            });
            var data = [];
            this.chartAll.forEach((item) => {
              let person = item.person ? item.person : this.$t(this.chart_type);
              if (!data[person]) {
                data[person] = {
                  label: person,
                  data: [],
                  fill: false,
                  tension: 0.1,
                  backgroundColor: this.COLORS[Math.floor(Math.random() * 9)],
                  borderWidth: 1,
                };
                for (let i = 0; i < categories.length; i++) {
                  data[person].data.push(0);
                }
              }
              let index = categories.indexOf(item.category);
              data[person].data[index] = item.cat_in_out
                ? item.total
                : item.total;
            });
            new Chart(ctx, {
              type: "horizontalBar",
              data: {
                labels: categories.map((i) => {
                  return this.$t(i);
                }),
                datasets: Object.values(data),
              },
              options: {
                responsive: true,
                scales: { indexAxis: "y" },
                plugins: {
                  title: {
                    display: true,
                    text: "income",
                  },
                },
              },
            });
          }, 0);
        });
    },
    changeCalFormat(item) {
      this.filter_type = item;

      if (this.filter_type == "monthly") {
        this.calFormat = "MM.yyyy";
        this.minimumView = "month";
      } else {
        this.calFormat = "yyyy";
        this.minimumView = "year";
      }
    },
    endMonthDay() {
      const endDate = new Date(
        this.chartDate.getFullYear(),
        this.chartDate.getMonth() + 1,
        0
      );
      return endDate.getDate();
    },
    nextMonth() {
      if (this.filter_type == "monthly") {
        if (this.chartDate.getMonth() == 11) {
          this.chartDate = new Date(this.chartDate.getFullYear() + 1, 0, 1);
        } else {
          this.chartDate = new Date(
            this.chartDate.getFullYear(),
            this.chartDate.getMonth() + 1,
            1
          );
        }
      } else {
        this.chartDate = new Date(this.chartDate.getFullYear() + 1, 11, 31);
      }
    },
    previousMonth() {
      if (this.filter_type == "monthly") {
        if (this.chartDate.getMonth() == 0) {
          this.chartDate = new Date(this.chartDate.getFullYear() - 1, 11, 1);
        } else {
          this.chartDate = new Date(
            this.chartDate.getFullYear(),
            this.chartDate.getMonth() - 1,
            1
          );
        }
      } else {
        this.chartDate = new Date(this.chartDate.getFullYear() - 1, 11, 31);
      }
    },
  },
  mounted() {
    this.getChartAll();
  },
};
</script>

<style></style>
